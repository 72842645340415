







































import { defineComponent, ref, computed } from '@vue/composition-api';
import { Lobby } from '../logic/lobby';
import store from '../store';
import { Seat } from '../logic/game';
import { Player } from '../logic/player';

export default defineComponent({
  name: 'Lobby',
  props: {
    lobby: { type: Lobby, required: true }
  },
  setup: (props, ctx) => {
    const chosenSeat = ref('');
    const name = computed(() => store.state.clientState.name);
    const mySeat = computed(() => store.getters.mySeat);

    const chooseSeat = (seat: Seat) => {
      console.log('Choosing seat', seat);
      chosenSeat.value = seat;
    };

    const sit = () => {
      console.log(`Taking seat as ${name.value}...`);
      store.dispatch('takeSeat', { seat: chosenSeat.value });
      chosenSeat.value = '';
    };

    const stand = () => {
      store.dispatch('stand', {seat: mySeat.value});
      chosenSeat.value = '';
    }
    
    const ghost = () => {
      store.dispatch('ghostSeat', { seat: chosenSeat.value });
      chosenSeat.value = '';
    };

    const start = () => store.dispatch('startGame');

    const copyLink = async () => {
      const gameId = store.state.clientState.gameId;
      if(gameId === undefined) return;
      const routeData = ctx.root.$router.resolve({name: 'Game', params: {id: gameId}});
      const uri = `https://${location.host}${routeData.href}`;
      await navigator.clipboard.writeText(uri);
    };
  
    const ghostTab = () => {
      const gameId = store.state.clientState.gameId;
      if(gameId === undefined) return;
      const ghostId = Player.getId('');
      const routeData = ctx.root.$router.resolve({name: 'Game', query: {userId: ghostId}, params: {id: gameId}});
      window.open(routeData.href,'_blank');
    }

    return {
      sit,
      ghost,
      chooseSeat,
      chosenSeat,
      name,
      mySeat,
      start,
      copyLink,
      ghostTab,
      stand
    };
  },
});
