


































import { defineComponent, computed } from '@vue/composition-api';
import { Game } from '../logic/game';
import PlayerName from '@/components/PlayerName.vue';
import HandSvg from '@/components/svg/Hand.vue';
import StackSvg from '@/components/svg/Stack.vue';
import store from '../store';

export default defineComponent({
  name: 'PlayerInfo',
  components:  {
    PlayerName,
    HandSvg,
    StackSvg
  },
  props: {
    game: { type: Game, required: true },
    seat: String,
    active: String
  },
  setup: props => {
    const player = computed(() => store.getters.player(props.seat));

    return {
      player
    };
  }
})
