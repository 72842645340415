

























import { defineComponent, computed } from '@vue/composition-api';
import store from '../store';
import { Seat } from '../logic/game';
import { Suit } from '../logic/gems-deck';

export default defineComponent({
  name: 'GameHeader',
  props: {
    seat: { type: String as () => Seat, required: false },
  },
  setup: () => {
    const currentTrick = computed(() => store.getters.currentTrick);
    const allCardsPassed = computed(() => store.getters.allCardsPassed);

    const suits = computed(() => {
      if(store.state.sharedState.stage === 'none') return undefined;
      if(store.state.sharedState.stageState.type === 'tichu') return [];
      const gemsuits: Suit[] = ['blue', 'green', 'black', 'red'];
      return gemsuits;
    });

    const endHand = () => {
      store.commit('toggleEndHandModal');
    };

    const pickUp = async () => {
      await store.dispatch('pickUpPassedCards');
    };

    const resync = async () => {
      await store.dispatch('sendState');
    };

    const rewind = async () => {
      await store.dispatch('rewind');
    };

    return {
      currentTrick,
      allCardsPassed,
      suits,
      endHand,
      pickUp,
      resync,
      rewind
    }
  }
});
