















import Hand from '@/components/Hand.vue';
import PlayerInfo from '@/components/PlayerInfo.vue';
import { defineComponent, computed } from '@vue/composition-api';
import store from '@/store';
import { Game } from '@/logic/game';
import { Card } from '@/logic/card';

export default defineComponent({
  name: "Player",
  components: {
    Hand,
    PlayerInfo
  },
  props: {
    game: { type: Game, required: true },
    seat: String,
    active: String
  },
  setup: props => {
    const player = computed(() => store.getters.player(props.seat));

    return {
      player
    };
  }
});
