










import NewGame from '@/components/NewGame.vue';
import { defineComponent, ref } from '@vue/composition-api';
import { GameType } from '@/logic/game';

export default defineComponent({
  name: 'Home',
  components: {
    NewGame,
  },
  filters: {
    capitalize: (value: string) => {
      if (!value) return '';
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    }
  },
  setup: () => {
    const gameType = ref<GameType>('tichu');
     
    const changeGame = () => {
      if(gameType.value === 'tichu') gameType.value = 'gems';
      else gameType.value = 'tichu';
    }

    return {
      gameType,
      changeGame
    };
  }
});
