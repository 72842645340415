




























import Card from '@/components/Card.vue';
import { defineComponent, computed, ref, watch } from '@vue/composition-api';
import { Seat } from '@/logic/game';
import store from '@/store';

export default defineComponent({
  name: 'Tricks',
  components: {
    Card
  },
  props: {
    seat: { type: String as () => Seat, required: false }
  },
  setup: (props) => {
    const trick = ref<Vue | null>(null);
    const currentTrick = computed(() => store.getters.currentTrick);
    const name = computed(() => (seat: Seat) => store.getters.player(seat).name);

    watch(currentTrick, () => {
      if (trick.value) {
        const el = trick.value.$el;
        el.children[el.childElementCount - 2]
          ?.scrollIntoView({ behavior: 'smooth', block: 'end' });
      }
    });

    const take = async () => {
      await store.dispatch('take', { seat: props.seat, cards: currentTrick.value });
    };

    return  {
      currentTrick,
      name,
      trick,
      take,
    };
  },
});
