














import { defineComponent, ref, computed } from '@vue/composition-api';
import store from '../store';
import Edit from '@/components/svg/Edit.vue';
import { Seat } from '../logic/game';

export default defineComponent({
  name: 'PlayerName',
  components: {
    Edit
  },
  props: {
    seat: { type: String as () => Seat, required: false },
    active: String
  },
  setup: (props) => {
    const inputName = ref('');

    const edit = ref(false);

    const playerName = computed(() => {
        const name = store.state.sharedState.stage != 'game' ? '' : 
            store.state.sharedState.stageState.seats[props.seat as Seat].name;
        return name ? name : 'Click to enter your name!'
    });

    const editName = () => {
        console.log('edit me!');
        if(edit.value) return;
        edit.value = true;
        inputName.value = playerName.value;
    };

    const submitName = async () => {
        if(!edit.value) return;
        await store.dispatch('changeName', {seat: props.seat, name: inputName.value})
        edit.value = false;
    }

    const cancelEdit = () => {
        edit.value = false;
    }

    return {
        inputName,
        edit,
        playerName,
        editName,
        submitName,
        cancelEdit
    };
  },
});
