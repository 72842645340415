













































import { defineComponent, ref, computed } from '@vue/composition-api';
import router from '../router';
import store from '../store';
import { GameType } from '../logic/game';

export default defineComponent({
  name: 'NewGame',
  props: {
    gameType: { type: String as () => GameType, required: true }
  },
  setup: (props) => {
    const playername = ref('');
    const starting = ref(false);
    const gameid = computed({
      get: () => store.state.clientState.gameId, 
      set: (newValue: string | undefined) => {
          store.commit('setGame', {gameId: newValue});
        } 
    });
    const userid = computed(() => store.state.clientState.userId);

    const submit = async () => {
      console.log(`starting new game!`)
      starting.value = true;
      store.commit('startLobby', {type: props.gameType});

      store.commit('makeUserId', {name: playername.value});
      store.commit('joinLobby', { name: playername.value, game: gameid.value });
      router.push({path:`game/${gameid.value}`, query: {userId: userid.value} });
    };

    const join = async () => {
      starting.value = true;
      store.commit('makeUserId', {name: playername.value});
      store.commit('joinLobby', {name: playername.value, game: gameid.value})
      router.push({path:`game/${gameid.value}`, query: {userId: userid.value} });
    }

    return {
      playername,
      gameid,
      submit,
      join,
      starting
    };
  },
});
