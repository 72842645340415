



































































import draggable from 'vuedraggable';
import CardComponent from "@/components/Card.vue";
import PlayerInfo from '@/components/PlayerInfo.vue';
import { defineComponent, ref, computed, watch } from "@vue/composition-api";
import store from "../store";
import { Card } from "@/logic/card";
import { Seat, SeatMap, Game } from "@/logic/game";

export default defineComponent({
  name: "Hand",
  components: {
    Card: CardComponent,
    Draggable: draggable,
    PlayerInfo
  },
  props: {
    seat: { type: String as () => Seat, required: true },
    active: { type: String as () => Seat, required: true },
    game: { type: Game, required: true },
  },
  setup: (props, ctx) => {
    const getPlayer = (seat: Seat) => store.getters.player(seat);
    const thisPlayer = computed(() => getPlayer(props.seat));

    /**
     * Passing
     */

    const passObject = { north: null, south: null, east: null, west: null };
    delete passObject[props.seat];
    const passes = ref<SeatMap<Card | null>>(passObject);
    const availablePasses = computed(() => {
      const available: Seat[] = [];
      for (const seat in passes.value) {
        if (!passes.value[seat as Seat]) {
          available.push(seat as Seat);
        }
      }

      return available;
    });
    const canPass = computed(() => {
      const handState = store.state.clientState.handState;
      return handState.pickedUpSecondDeal
        && !thisPlayer.value.passedCards;
    });
    const canPlay = computed(() => thisPlayer.value.passedCards);
    const passingCard = ref<Card | null>(null);

    const passCardToSeat = (card: Card, seat: Seat) => {
      passingCard.value = null;
      passes.value[seat] = card;
    };

    const seatCardIsPassedTo = (card: Card): Seat | undefined => {
      for (const seat in passes.value) {
        if (passes.value[seat as Seat]?.id === card.id) {
          return seat as Seat;
        }
      }

      return undefined;
    };

    const cancelPass = (card: Card) => {
      passingCard.value = null;
      const seat = seatCardIsPassedTo(card);
      if (seat) {
        passes.value[seat] = null;
      }
    };


    /**
     * Selection
     */

    const selected = ref<string[]>([]);

    const isSelected = computed(() => (card: Card) =>
      !!selected.value.find(c => c === card.id)
    );

    const rightclick = (card: Card) => {
      if(card.suit === 'blank') return;
      const checkbox = (ctx as any).refs[card.id][0];
      checkbox.click();
    }

    const toggle = (card: Card) => {
      const checkbox = (ctx as any).refs[card.id][0];
      if (checkbox) {
        if (!checkbox.checked) {
        cancelPass(card);
        }
      }
    };

    /**
     * Two-stage deal
     */

    const hideDeal = computed(
      () => !store.state.clientState.handState.pickedUpSecondDeal
    );
    const visibleHand = computed(() =>
      Array.from(thisPlayer.value.hand).filter(
        card => !hideDeal.value || !thisPlayer.value.secondDeal.has(card)
      ) as Card[]
    );

    const sortReverse = ref(false);
    const firstSort = ref(false);
    
    const minBlank: Card = {
        id: 'blankMin',
        name: '',
        value: 0,
        rank: -100000,
        suit: 'blank',
        serializedId: -1
    }

    const maxBlank: Card = {
        id: 'blankMax',
        name: '',
        value: 0,
        rank: 100000,
        suit: 'blank',
        serializedId: -1
    }

    const sortedHand = computed({
      get: () => firstSort.value 
        ? store.state.clientState.handState.sortedHand 
        : computeSortedHand(visibleHand.value, store.state.clientState.handState.sortedHand, sortReverse.value),
      set: (value) => store.state.clientState.handState.sortedHand = value
    });
    watch(visibleHand, (newHand, oldHand) => {
      sortedHand.value = computeSortedHand(newHand, sortedHand.value, sortReverse.value);
    });
    
    const computeSortedHand = (newHand: readonly Card[] , sortedHand: readonly Card[], sortReverse: boolean): Card[] => {
      firstSort.value = true;
      const newHandSet = new Set<Card>(newHand.concat(minBlank, maxBlank));
      let newSortedHand: Card[] = [];

      sortedHand.forEach((card, index) => {
        if(newHandSet.has(card)) {
          newSortedHand.push(card);
          newHandSet.delete(card);
        }
      });

      // TODO: toggle default sort direction
      const remaining = sortReverse
        ? Array.from(newHandSet).sort((a, b) => b.rank - a.rank)
        : Array.from(newHandSet).sort((a, b) => a.rank - b.rank);
      newSortedHand = newSortedHand.concat(remaining);

      return newSortedHand;
    }

    const sort = () => {
      const sorted = sortReverse.value 
        ? Array.from(sortedHand.value).sort((a, b) => b.rank - a.rank)
        : Array.from(sortedHand.value).sort((a, b) => a.rank - b.rank);
      sortedHand.value = sorted;
    }

    const pickup = () => {
      store.commit('pickUpSecondDeal');
    };

    const play = async () => {
      const selectedCards: Card[] = [];            
      sortedHand.value.forEach(card => { if(selected.value.findIndex(c => c === card.id) >= 0) selectedCards.push(card); });
      await store.dispatch('play', { seat: props.seat, cards: selectedCards });
      selected.value = [];
    };

    const pass = async () => {
      await store.dispatch('passCards', { fromSeat: props.seat, to: passes.value });
      selected.value = [];
      passes.value = { north: null, south: null, east: null, west: null };
      delete passes.value[props.seat];
      passingCard.value = null;
    };

    return {
      availablePasses,
      canPass,
      canPlay,
      cancelPass,
      hideDeal,
      isSelected,
      getPlayer,
      pass,
      passes,
      passingCard,
      passCardToSeat,
      pickup,
      play,
      seatCardIsPassedTo,
      selected,
      visibleHand,
      rightclick,
      toggle,
      sortedHand,
      sortReverse,
      sort
    };
  }
});
