






















import { defineComponent, computed } from '@vue/composition-api';
import History from '@/components/History.vue';
import Player from '@/components/Player.vue';
import Tricks from '@/components/Tricks.vue';
import { Seat, Game } from '@/logic/game';
import store from '@/store';
import { Card } from '../logic/card';

export default defineComponent({
  name: 'Table',
  components: {
    History,
    Player,
    Tricks
  },
  props: {
    game: { type: Game, required: true },
    seat: { type: String as () => Seat, required: false },
    secondDeal: { type: (Set as unknown) as () => Set<Card>, required: false }
  },
  setup: (props) => {
    const order = ['north', 'east', 'south', 'west'];
    const placement = ['left', 'partner', 'right', 'me']; 
    if (props.seat) {
      let s;
      while ((s = order.shift()) && (s !== props.seat)) {
        order.push(s)
      }
      if (s) order.push(s);
    }
    const seats = computed(() => store.getters.seats);

    const player = computed(() => props.seat
      ? store.getters.player(props.seat)
      : undefined
    );
    const hideDeal = computed(
      () => !store.state.clientState.handState.pickedUpSecondDeal
    );

    const actionHistory = computed(() => store.state.stateHistory
      .filter(s => s.stage === 'game')
      .reverse()
      .slice(0, 5)
    );

    return {
      actionHistory,
      hideDeal,
      player,
      placement,
      order,
      seats
    }
  }
});
