

































import { defineComponent, computed, ref } from '@vue/composition-api';
import store from '../store';
import { Seat } from '../logic/game';

export default defineComponent({
  name: 'EndHandModal',
  setup: () => {
    const scores = computed(() => store.getters.score);
    const getPlayer = (seat: Seat) => store.getters.player(seat);
    const showScores = ref(false);
    const seatsOut = computed(() => store.getters.seatsOut)

    const deal = async () => {
      store.commit('toggleEndHandModal');
      await store.dispatch('deal');
    }

    return {
      getPlayer,
      deal,
      scores,
      seatsOut,
      showScores
    };
  }
});
