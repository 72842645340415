












import { defineComponent } from '@vue/composition-api';
import { SerializedState } from '../logic/state';

export default defineComponent({
  name: 'History',
  props: {
    actions: { type: Array as () => SerializedState[], required: true }
  },
  setup: () => {
    return {
    };
  },
});
