










import ActiveGame from '@/components/ActiveGame.vue';
import Lobby from '@/components/Lobby.vue';
import { defineComponent, computed } from '@vue/composition-api';
import store from '../store';
import { Route } from 'vue-router';


export default defineComponent({
  name: 'Game',
  beforeRouteEnter: async (to: Route, from: unknown, next: (route?: string) => void) => {
    if(store.state.clientState.userId === undefined && to.query.userId != undefined) {      
      store.commit('setUserId', { userId: to.query.userId });
      store.commit('joinLobby', {name: 'ghost', game: to.params.id})
    }
    if (store.state.clientState.gameId === undefined) {
      store.commit('setGame', { gameId: to.params.id });
      next('/');      
    } else {
      await store.dispatch('connect', { gameId: store.state.clientState.gameId });
      next();
    }
  },
  components: {
    ActiveGame,
    Lobby,
  },
  setup: () => {
    const sharedState = computed(() => store.state.sharedState);
    const mySeat = computed(() => store.getters.mySeat);

    return {
      sharedState,
      mySeat
    };
  },
});
